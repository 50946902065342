import React from "react"
import { RichText } from "prismic-reactjs"
import { graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import { Row, Col } from "react-grid-system"

import FirstLevelPage from "./FirstLevelPage"
import { PrismicQuery, PrismicEdges, PrismicImage } from "../types/prismic"
import { TransitionProps } from "./ITransition"
import SEO from "./Seo"
import BossStamp from "../assets/logos/BOSS_Stamp.svg"
import LazyImage from "./LazyImage"
import { linkResolver } from "../util/linkResolver"

export const query = graphql`
  query PrismicData($uid: String) {
    prismic {
      allPages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            title
            content
            meta_description
            gallery {
              image
            }
            template
            body {
              __typename
              ... on PRISMIC_PageBodyContent {
                type
                label
                primaryContent: primary {
                  text
                }
              }
              ... on PRISMIC_PageBodyButton {
                type
                label
                primary {
                  text
                  link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ImageLink {
                      url
                    }
                    ... on PRISMIC_Project {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Project_collection {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Project_type {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Hire_selection_page {
                      _meta {
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

type ContentSlice = {
  type: "content"
  primaryContent: string[]
}

type ButtonSlice = {
  type: "button"
  primary: {
    text: string
    link: {
      __typename: string
      _linkType: string
      url?: string
      _meta?: {
        uid: string
      }
    }
  }
}

type PageEdge = {
  _meta: {
    uid: string
  }
  title: string
  content: string
  meta_description: string
  body: ContentSlice[] | ButtonSlice[]
  gallery: {
    image: PrismicImage
  }
  template: "Default" | "Gallery"
}

type AllPages = {
  allPages: PrismicEdges<PageEdge>
}

interface PageProps extends PrismicQuery<AllPages>, TransitionProps {}

const buttonLinkResolver = ({ link, text }: any) => {
  let path = "#"
  let external = false
  switch (link.__typename) {
    case "PRISMIC__ImageLink":
    case "PRISMIC__FileLink":
    case "PRISMIC__ExternalLink": {
      path = link.url
      external = true
      break
    }
    case "PRISMIC_Project": {
      path = `/project/${link._meta.uid}`
      break
    }
    case "PRISMIC_Contact_page": {
      path = "/contact"
      break
    }
    case "PRISMIC_Page": {
      path = `/page/${link._meta.uid}`
      break
    }
    case "PRISMIC_Project_collection":
    case "PRISMIC_Project_type": {
      path = `/${link._meta.uid}`
      break
    }
    case "PRISMIC_Hire_selection_page": {
      path = "/hire/select"
      break
    }
    default: {
      path = "#"
      break
    }
  }
  if (external)
    return (
      <a
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        className="button"
      >
        {text}
      </a>
    )
  return (
    <TransitionLink to={path} className="button">
      {text}
    </TransitionLink>
  )
}

const DefaultLayout: React.FC<{ data: PageEdge }> = ({ data }) => {
  return (
    <div>
      <h1>{RichText.asText(data.title)}</h1>
      {data.body.map((bodyItem, idx) => (
        <div key={idx}>
          {bodyItem.type === "content" && (
            <RichText
              render={bodyItem.primaryContent.text}
              linkResolver={linkResolver}
            />
          )}
          {bodyItem.type === "button" && (
            <p>{buttonLinkResolver(bodyItem.primary)}</p>
          )}
        </div>
      ))}
      {data._meta.uid === "about-boss-construction" && (
        <BossStamp className="boss-stamp" />
      )}
    </div>
  )
}

const GalleryLayout: React.FC<{ data: PageEdge }> = ({ data }) => {
  return (
    <Row>
      <Col xs={12} md={4}>
        <DefaultLayout data={data} />
      </Col>
      {data.gallery.map(({ image }, idx) => (
        <Col key={image.url} xs={12} md={idx === 0 ? 8 : 4}>
          <LazyImage image={image} />
        </Col>
      ))}
    </Row>
  )
}

const Page: React.FC<PageProps> = props => {
  const data = props.data.prismic.allPages.edges?.[0]?.node
  if (!data) return null
  return (
    <FirstLevelPage
      transitionStatus={props.transitionStatus}
      useInnerPage={data.template !== "Gallery"}
    >
      <div className="page__wrap">
        <SEO
          title={RichText.asText(data.title)}
          description={data.meta_description}
        />
        {data.template === "Gallery" ? (
          <GalleryLayout data={data} />
        ) : (
          <DefaultLayout data={data} />
        )}
      </div>
    </FirstLevelPage>
  )
}

export default Page
